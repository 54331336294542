
import { defineComponent, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Text',
  components: {},
  props: {
    title: String,
    color: String,
    fontSize: String,
    fontWeight: String,
    lineHeight: String
  },
  setup() {
    const { locale } = useI18n();
    const activeLang = ref(localStorage.getItem('locale'));

    watch(
      locale,
      () => {
        activeLang.value = localStorage.getItem('locale');
      },
      { deep: true }
    );
    return {
      activeLang
    };
  }
});
