
export default {
  name: 'Modal',
  setup(_: any, content: any) {
    const close = () => {
      content.emit('close');
    };
    return { close };
  }
};
