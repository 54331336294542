import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import '@/styles/index.scss';
import en from '@/assets/lang/en-US.json';
import km from '@/assets/lang/km-KM.json';

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('locale') ?? 'en-US',
  fallbackLocale: 'en-US',
  messages: {
    'en-US': en,
    'km-KM': km
  }
});

createApp(App).use(i18n).mount('#app');
