
import {
  defineComponent,
  provide,
  onMounted,
  onBeforeUnmount,
  ref,
  nextTick
} from 'vue';
import Top from '@/views/DashBoard.vue';

export default defineComponent({
  components: { Top },
  setup() {
    nextTick(() => {
      document.title = 'Eaon-AR';
    });
    const isMobile = ref<boolean>(false);

    provide('isMobile', isMobile);

    const resizeWidth = () => {
      if (window.innerWidth <= 767) {
        isMobile.value = true;
      } else {
        isMobile.value = false;
      }
    };

    const calcSize = () => {
      if (screen.width <= 767) {
        isMobile.value = true;
      } else {
        isMobile.value = false;
      }
    };

    onMounted(() => {
      calcSize();
      if (!isMobile.value) {
        resizeWidth();
      }
      window.addEventListener('resize', resizeWidth);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', resizeWidth);
    });

    return { isMobile };
  }
});
