
import { defineComponent, inject, ref } from 'vue';
import Text from '@/components/Text/index.vue';
import Modal from '@/components/Modal/index.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'DashBoard',
  components: { Text, Modal },
  setup() {
    const { t, locale } = useI18n();
    const activeLang = ref(localStorage.getItem('locale') || 'en-US');
    const isMobile: any = inject('isMobile');
    const visible = ref(false);
    const visiblePlay = ref(false);

    const onChangeLanguage = (val: string) => {
      locale.value = val;
      localStorage.setItem('locale', val);
      activeLang.value = val;
    };

    const openModalInfo = () => {
      visible.value = true;
    };

    const openModalPlay = () => {
      if (isMobile.value) {
        window.open(
          'https://lastmileworks.8thwall.app/webar',
          '_parent'
        );
      } else {
        visiblePlay.value = true;
      }
    };

    const close = () => {
      visible.value = false;
      visiblePlay.value = false;
    };

    return {
      isMobile,
      t,
      locale,
      activeLang,
      onChangeLanguage,
      openModalInfo,
      close,
      visible,
      visiblePlay,
      openModalPlay
    };
  }
});
